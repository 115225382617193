
.Contact form {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #eee;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.Contact input, 
.Contact textarea {
    margin-bottom: 16px;
    padding: 9px;
    border: 1px solid #eee;
    outline: none;
    font-size: 18px;
    transition: border-color 0.2s, background-color 0.2s;
}

.Contact textarea {
    resize: vertical;
}

.Contact input:focus, 
.Contact textarea:focus {
    padding: 8px;
    border: 2px solid var(--blue-light);
}

.Contact input.error,
.Contact textarea.error {
    padding: 8px;
    border: 2px solid var(--red-error);
}

.Contact button {
    height: 48px;
    font-weight: bold;
    font-size: 15px;
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--green-mid);
    box-shadow: 0px 1px 1px var(--blue-dark);
    outline: none;
    transition: all 300ms;
}

.Contact button:hover {
    cursor: pointer;
    background: #388d67;
}


.Contact button:active {
    background: #33815e;
}

.ContactForm__Response {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    height: 210px;
    color: var(--blue-dark);
}
